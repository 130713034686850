import React from 'react';
import { Link } from 'gatsby';
import { chakra, Box, Text, Container } from '@chakra-ui/react';
import { Flex } from 'rebass';
import ComponentWrapper from './ComponentWrapper';
import { Small } from '../styles/HeadingStyles';

const Column = chakra(Box, {});

Column.defaultProps = {
  width: ['100%', '255px'],
  mr: [0, '30px'],
  flexGrow: 0,
  mt: ['52px', 0],
};

const MenuList = chakra('ul', {
  baseStyle: {
    margin: '0.5rem 0',
    paddingRight: '1rem',
    listStyleType: 'none',
    paddingLeft: '0',
    fontSize: '0.8889rem',
    li: {
      marginBottom: '0.3rem',
      padding: '0',
      a: {
        color: '#333',
      },
    },
  },
});

const SocialIcon = chakra('li', {
  baseStyle: {
    flexBasis: '39px',
    marginRight: '8px',
  },
});

/** Our footer component - Everything here is static at the moment */
const Footer = () => {
  // This is added to make a dynamic copyright date at the bottom of the site
  const date = new Date();
  const year = date.getFullYear();

  return (
    <Container maxWidth="full">
      <ComponentWrapper variant="footer" pt={[16]} mx="auto" px={[3, 3, 3, 0]}>
        <Column>
          <Small fontWeight={[3]} paddingBottom="4">
          Sales and Support Inquiries:
          </Small>
          <MenuList>
            <li>
              <Link to="mailto:support@safer.me">support@safer.me</Link>
            </li>
          </MenuList>
        </Column>

        <Column>
          <Small fontWeight={[3]} paddingBottom="4">
            About
          </Small>
          <MenuList>
            <li>
              <Link to="/about-us/">About Us</Link>
            </li>
            <li>
              <Link to="/about-us/careers/">Careers</Link>
            </li>

            <li>
              <Link to="/blog/">Blog</Link>
            </li>
            <li>
              <a
                href="https://help.safer.me/knowledge"
                target="_blank"
                rel="noreferrer"
              >
                Help Centre
              </a>
            </li>
            <li>
              <a
                href="https://saferme.github.io/saferme-api-docs/"
                target="_blank"
                rel="noreferrer"
              >
                API Docs
              </a>
            </li>
            <li>
              <Link to="/about-us/news-and-media/">Media Enquiries</Link>
            </li>
            <li>
              <Link to="/tools/toolbox-talk-topics/">
                Toolbox Talk Topic Tool
              </Link>
            </li>
            <li>
              <Link to="/health-safety-consultant">
                Health & Safety Consultant Directory
              </Link>
            </li>
          </MenuList>
        </Column>
        <Column>
          <Small fontWeight={[3]} paddingBottom="4">
            Legal
          </Small>
          <MenuList>
            <li>
              <Link to="/terms-and-conditions/">Terms and Conditions</Link>
            </li>
            <li>
              <Link to="/privacy-policy/">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/account-owner-terms-conditions/">
                Account Owner Terms
              </Link>
            </li>
          </MenuList>
        </Column>
        <Column mr="0">
          <Small fontWeight={[3]} paddingBottom="4">
            Social
          </Small>
          <MenuList style={{ display: 'flex' }}>
            <SocialIcon>
              <a
                href="https://nz.linkedin.com/company/safermeglobal"
                aria-label="LinkedIn link"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 37 37"
                  role="img"
                >
                  <title>Linkedin</title>
                  <defs />
                  <g id="FooterSocialLinkedIn" data-name="Layer 2">
                    <g id="FooterSocialLinkedIn-2" data-name="Layer 1">
                      <g>
                        <circle
                          className="cls-1"
                          fill="#fff"
                          cx="18.5"
                          cy="18.5"
                          r="18.5"
                        />
                        <g>
                          <path
                            className="cls-2"
                            fill="#25404e"
                            d="M9.63,14.29h3.9V26.81H9.63Zm1.95-6.23a2.26,2.26,0,1,1-2.26,2.26,2.26,2.26,0,0,1,2.26-2.26"
                          />
                          <path
                            className="cls-2"
                            fill="#25404e"
                            d="M16,14.29H19.7V16h.05a4.08,4.08,0,0,1,3.68-2c3.94,0,4.67,2.59,4.67,6v6.87H24.21V20.72c0-1.45,0-3.32-2-3.32S19.85,19,19.85,20.61v6.2H16Z"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </a>
            </SocialIcon>
            <SocialIcon>
              <a
                href="https://www.facebook.com/SaferMeGlobal/"
                aria-label="Facebook link"
              >
                <svg
                  version="1.1"
                  id="FooterSocialFB"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 37 37"
                  // style={{ enableBackground: "new 0 0 37 37" }}
                  role="img"
                >
                  <title>Facebook logo to SaferMe FACEBOOK PAGE</title>

                  <circle
                    className="st0"
                    fill="#FFFFFF"
                    cx="18.5"
                    cy="18.5"
                    r="18.5"
                  />
                  <path
                    className="st1"
                    fill="#25404E"
                    d="M14.9,30.7V20H11v-4.4h3.9l0-3.4c0-3.8,2.3-6,5.8-6c1.7,0,3.4,0.3,3.4,0.3v3.7h-1.9c-1.9,0-2.5,1.2-2.5,2.4
  l0,2.9h4.2L23.2,20h-3.6v10.7H14.9z"
                  />
                </svg>
              </a>
            </SocialIcon>
            <SocialIcon>
              <a
                href="https://twitter.com/safermeglobal"
                aria-label="twitter Link"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 37 37"
                  role="img"
                >
                  <title>Twitter logo to SaferMe twitter PAGE</title>
                  <defs />
                  <g id="FooterSocialTwitter" data-name="Layer 2">
                    <g id="FooterSocialTwitter-2" data-name="Layer 1">
                      <g>
                        <circle
                          className="cls-1"
                          fill="#fff"
                          cx="18.5"
                          cy="18.5"
                          r="18.5"
                        />
                        <path
                          className="cls-2"
                          fill="#25404e"
                          d="M14.85,28.54A13.23,13.23,0,0,0,28.17,15.22c0-.2,0-.4,0-.6a9.49,9.49,0,0,0,2.34-2.43,9,9,0,0,1-2.69.74,4.66,4.66,0,0,0,2.06-2.59,9.39,9.39,0,0,1-3,1.14,4.68,4.68,0,0,0-8.1,3.2,4.74,4.74,0,0,0,.12,1.07,13.3,13.3,0,0,1-9.64-4.89,4.65,4.65,0,0,0,1.45,6.24,4.6,4.6,0,0,1-2.12-.58v.06a4.68,4.68,0,0,0,3.75,4.59,5,5,0,0,1-1.23.16,5.05,5.05,0,0,1-.88-.08,4.68,4.68,0,0,0,4.37,3.25,9.41,9.41,0,0,1-5.81,2,10.37,10.37,0,0,1-1.12-.06,13.3,13.3,0,0,0,7.17,2.1"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </a>
            </SocialIcon>
            <SocialIcon>
              <a href="https://www.youtube.com/channel/UCq5yC2SHAXeqXnOkuTxZuZw">
                <svg
                  role="img"
                  id="FooterSocialYouTube"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 37 37"
                >
                  <title>Youtube logo to SaferMe Youtube page PAGE</title>
                  <style type="text/css" />
                  <g>
                    <circle fill="#FFFFFF" cx="18.5" cy="18.5" r="18.5" />
                    <g>
                      <path
                        fill="#FFFFFF"
                        d="M30.2,12.6c-0.3-1.1-1.1-1.9-2.2-2.2c-1.9-0.5-9.5-0.5-9.5-0.5s-7.6,0-9.5,0.5c-1,0.3-1.9,1.1-2.2,2.2
      c-0.5,1.9-0.5,5.9-0.5,5.9s0,4,0.5,5.9c0.3,1.1,1.1,1.9,2.2,2.2c1.9,0.5,9.5,0.5,9.5,0.5s7.6,0,9.5-0.5c1-0.3,1.9-1.1,2.2-2.2
      c0.5-1.9,0.5-5.9,0.5-5.9S30.7,14.5,30.2,12.6z"
                      />
                      <polygon points="16,22.1 16,14.9 22.4,18.5 		" />
                    </g>
                  </g>
                </svg>
              </a>
            </SocialIcon>
          </MenuList>
        </Column>
      </ComponentWrapper>

      <ComponentWrapper
        variant="footer"
        pt={[16, 32]}
        style={{ borderTop: '1px solid #fff' }}
        mx="auto"
        maxWidth={['maxWidth']}
        pb={[16]}
        fontSize="12px"
      >
        Copyright SaferMe Limited 2015 - {year}
      </ComponentWrapper>
    </Container>
  );
};

export default Footer;
