import { createBreakpoints } from '@chakra-ui/theme-tools';

export const breakpoints = createBreakpoints({
  // 640px
  sm: '40em',
  // 848px
  md: '53em',
  // 1109.6px
  lg: '69.38em',
  // 1280px
  xl: '80em',
  '2xl': '96em',
});
