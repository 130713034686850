export const BorderStyles = {
  variants: {
    default: {
      border: '1px solid #25404e',
    },
    formInputBorder: {
      border: '1px solid #6c6c6c',
    },
  },
  defaultProps: {
    variant: 'default',
  },
};
