import React from 'react';
import { Container, forwardRef, ContainerProps } from '@chakra-ui/react';

type CompnentWrapperProps = ContainerProps;

/** A simple container component for use inside a section. Uses Chakra UI Flex component */

const ComponentWrapper = forwardRef<CompnentWrapperProps, 'div'>(
  (props, ref) => {
    return (
      <Container
        {...props}
        flexBasis="100%"
        display={props.display || 'flex'}
        px={[3, 3, 3, 0]}
        flexWrap="wrap"
        maxWidth="maxWidth"
        ref={ref}
        mx="auto"
      >
        {props.children}
      </Container>
    );
  }
);

export default ComponentWrapper;

// flexBasis = '100%',
// maxWidth,
// variant,
// pt,
// ...props
// }) => (
// <Container
//   display="flex"
//   px={[3, 3, 3, 0]}
//   flexWrap="wrap"
//   {...props}
//   maxWidth="maxWidth"
//   variant={variant}
//   pt={pt}
// />
