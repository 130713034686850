import React from 'react';
import { Link } from 'gatsby';
import CookieConsent from 'react-cookie-consent';

/** Shows the cookie tracking warning on the bottom of the website. */
const CookieBar = (): JSX.Element => (
  <CookieConsent
    style={{
      width: '100%',
      backgroundColor: 'white',
      color: '#25404e',
      fontSize: '13px',
      padding: '14px',
      margin: 'auto',
      boxShadow: '0 -1px 7px rgba(0, 0, 0, 0.12)',
      justifyContent: 'center',
      gap: '20px',
    }}
    buttonStyle={{
      backgroundColor: '#0784b5',
      fontWeight: 'bold',
      fontSize: '16px',
      paddingLeft: '16px',
      paddingRight: '16px',
      color: 'white',
      border: 'solid 0px transparent',
      borderRadius: '5px',
      margin: '0',
      marginBottom: '10px',
    }}
    contentStyle={{ flex: '1 1 200', margin: '0' }}
    buttonText="Got it!"
  >
    SaferMe uses cookies to give you the best experience on our website. For
    more details see our <Link to="/privacy-policy">Privacy Policies</Link>.
  </CookieConsent>
);

export default CookieBar;
