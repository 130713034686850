import CustomLayout from "./wrapPageElement";
require("fontsource-manrope");
require("fontsource-manrope/600.css");
require("fontsource-manrope/800.css");
export const wrapPageElement = CustomLayout;

let nextRoute = ``;

export const onPreRouteUpdate = ({ location }) => {
  nextRoute = location.pathname;
};

window.addEventListener("unhandledrejection", (event) => {
  if (/loading chunk \d* failed./i.test(event.reason)) {
    if (nextRoute) {
      window.location.pathname = nextRoute;
    }
  }
});

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
  }
}