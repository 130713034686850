export const SectionStyles = {
  variants: {
    whiteSection: {
      color: 'heading',
    },
    lightGreySection: {
      bg: 'lightGrey',
      color: 'heading',
    },
    darkSection: {
      bg: 'secondary',
      color: 'white',
    },
    blueSection: {
      bg: 'primary',
      color: 'white',
    },
    nav: {
      fontWeight: '2',
      maxWidth: 'maxWidth',
      a: {
        textDecoration: 'none',
        _hover: {
          textDecoration: 'underline',
          textDecorationColor: 'text',
        },
      },
    },
    footer: {
      bg: 'lightGrey',
      color: 'text',
      fontSize: '0',
      h4: {
        fontSize: '0',
        mb: '4',
      },
      a: {
        color: 'text',
        textDecoration: 'none',
        _hover: {
          textDecoration: 'underline',
        },
      },
      transform: 'perspective(1px) translateZ(0)',
      transitionDuration: '0.3s',
    },
    leadBox: {
      p: '4',
      color: 'primaryLight',
      bg: 'black',
      borderRadius: 'default',
      borderWidth: '3',
      borderColor: 'primary',
      borderStyle: 'default',
      boxShadow: 'default',
    },
    tinted: {
      bg: 'lightGrey',
    },
    bio: {
      borderTop: '0px solid',
    },
  },
  defaultProps: {
    variant: 'whiteSection',
  },
};
