export const TextStyles = {
  baseStyle: {
    fontSize: '18px',
  },
  varients: {
    large: {
      fontSize: 32,
      lineHeight: [8, 32],
    },
    bigHeading: {
      textTransform: 'none',
    },
    subHeading: {
      fontWeight: ['2'],
      fontFamily: 'subheading',
      textTransform: 'uppercase',
    },
  },
};
