import React from 'react';
import Layout from './src/components/ui/Layout';
// import { GlobalStyle } from "./src/components/theme/GlobalStyles";
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { theme } from './src/@chakra-ui/gatsby-plugin/theme';

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
const wrapPageElement = ({ element, props }) => (
  <>
    <ChakraProvider resetCSS theme={theme}>
      <ColorModeScript initialColorMode="light" />
      <Layout {...props}>{element}</Layout>
    </ChakraProvider>
  </>
);

export default wrapPageElement;
