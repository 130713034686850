const FormInputStyles = {
  baseStyle: {
    display: 'block',
    width: '100%',
    padding: '0.375rem 0.75rem',
    lineHeight: '1.5',
    bg: '#fff',
    bgClip: 'padding-box',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    border: 'formInputBorder',
    borderRadius: 'default',
    fontSize: '3',
    color: '#495057',
  },
};

const FormLabelStyles = {
  baseStyle: {
    display: 'block',
    marginBottom: '0.3rem',
    borderWidth: '0px',
    borderRadius: 'default',
    fontSize: '4',
  },
  variant: {
    light: {
      color: 'primaryLight',
    },
    dark: {
      color: 'text',
    },
  },
  default: {
    variant: 'dark',
  },
};

const FormTextAreaStyles = {
  baseStyle: {
    display: 'block',
    width: '100%',
    padding: '0.375rem 0.75rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    bg: '#fff',
    bgClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
  },
};

const FormSelectStyles = {
  baseStyle: {
    display: 'block',
    width: '100%',
    padding: '0.375rem 0.75rem',
    lineHeight: '1.5',
    height: '2.5rem',
    border: 'formInputBorder',
    borderRadius: 'default',
    fontSize: '2',
    color: '#495057',
  },
};

export {
  FormInputStyles,
  FormLabelStyles,
  FormTextAreaStyles,
  FormSelectStyles,
};
